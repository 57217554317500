<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="plateNumber"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                v-if="$route.meta.authority.subPage.configure.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="add"
                v-if="$route.meta.authority.subPage.configure.button.add"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button type="info" @click="$router.go(-1)">返回</el-button>
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="80"
            type="index"
            v-if="
              $route.meta.authority.button.detail ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- v-if="$route.meta.authority.button.detail" -->
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.subPage.configure.button.detail"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.subPage.configure.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="$route.meta.authority.subPage.configure.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDt,
  // dateFormat,
  // setIndex,
  // exportExcelNew,
} from "../../../common/js/public.js";
export default {
  data() {
    return {
      formInline: {
        plateNumber: "",
        parkId: "",
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "plateNumber",
          label: "车牌号码",
        },
        // {
        //   prop: "aeraName",
        //   label: "生效区域",
        // },
        {
          prop: "createdTime",
          label: this.$t("list.add_time"),
        },
        {
          prop: "creatorName",
          label: "添加人",
        },
        // {
        //   prop: "type",
        //   label: "报警类型",
        //   formatter: (cellValue) => {
        //     if (cellValue.type == 0) {
        //       return "超长停车";
        //     } else {
        //       return "套牌车";
        //     }
        //   },
        // },
      ],
      pageNum: 1,
      pageSize: 15,
      total: 0,
    };
  },
  methods: {
    searchData() {
      if (this.formInline.plateNumber == "") {
        this.formInline.parkId = "";
      }
      let opt = {
        url: "/acb/2.0/monitorCar/list",
        method: "get",
        data: {
          carId: this.formInline.parkId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
        success: (res) => {
          if (res.state == 0) {
            if (!res.value) {
              this.tableData = [];
              this.total = 0;
            } else {
              this.tableData = res.value.list;
              this.total = res.value.total * 1 || 0;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    handleCurrentChange(v) {
      this.pageNum = v;
      this.searchData();
    },
    add() {
      this.$router.push({ path: "/monitorVehicleDetail" });
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let url = "/acb/2.0/parkRecord/plateNumber/list";
      this.$axios
        .get(url, {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.state == 0) {
              // res.value.forEach((v) => {
              //   v.value = v.plateNumber;
              // });
              cb(res.value);
            }
          }
        });
    },
    handleSelect(item) {
      // console.log(item);
      this.formInline.parkId = item.carId;
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        data.disabled = 1;
        this.$router.push({ path: "/monitorVehicleDetail", query: data });
      } else if (cmd == "b") {
        data.disabled = 0;
        this.$router.push({ path: "/monitorVehicleDetail", query: data });
      } else if (cmd == "c") {
        this.$confirm("此操作将删除监控车辆，是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let method = "post";
            this.$axios[method]("/acb/2.0/monitorCar/delete", {
              data: {
                monitorCarId: data.monitorCarId,
              },
            }).then((res) => {
              if (res.state == 0) {
                this.$alert("成功", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.searchData();
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
  },
  mounted() {
    this.searchData();
    console.log("监控车辆报警设置界面权限=>", this.$route.meta.authority);
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

}
</style>
